import classNames from 'classnames'
import * as React from 'react'
import BootstrapButton, { ButtonProps } from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

type Props = {
  ariaLabel?: string
  children: React.ReactNode
  className?: string
  disabled?: boolean
  formId?: string | undefined
  isBlackVariant?: boolean
  isDelete?: boolean
  isVersion2?: boolean
  loading?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  size?: 'sm' | 'lg'
  type?: 'button' | 'submit'
  variant?: ButtonProps['variant']
}

export const Button = ({
  ariaLabel,
  children,
  className,
  disabled,
  formId,
  isBlackVariant = false,
  isVersion2 = false,
  isDelete = false,
  loading,
  onClick,
  size,
  type = 'button',
  variant = 'primary',
}: Props): JSX.Element => (
  <BootstrapButton
    aria-label={loading ? undefined : ariaLabel}
    className={classNames(className, {
      blackVariant: isBlackVariant,
      delete: isDelete,
      version2: isVersion2,
    })}
    disabled={disabled || loading}
    form={formId}
    onClick={onClick}
    size={size}
    type={type}
    variant={variant}
  >
    {loading ? <Spinner aria-label="Loading" animation="border" size="sm" /> : children}
  </BootstrapButton>
)
